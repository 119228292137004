import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'

import axios from 'axios'
import VueAxios from 'vue-axios'

import 'fullpage.js/vendors/scrolloverflow' // Optional. When using scrollOverflow:true
import VueFullPage from 'vue-fullpage.js'

import VideoBg from 'vue-videobg'

import wx from 'weixin-js-sdk'

Vue.prototype.bus = new Vue()

Vue.prototype.$mobileCheck = () => {
    let check = false
    if('ontouchstart' in window) {
        check = true
    }
    return check
};

Vue.prototype.$share = () => {
  const link = location.href.split('#')[0];
  fetch('/api/wechat/shared?url=' + link)
  .then((response) => response.json()).then(e => {
    
    let title = e.name
    let desc = e.desc
    let imgUrl = e.image_url
    let wechat = JSON.parse(e.wechat)

    wx.config({
      debug: false, // 关闭调试模式
      appId: wechat.appId, // 必填，公众号的唯一标识
      timestamp: wechat.timestamp, // 必填，生成签名的时间戳
      nonceStr: wechat.nonceStr, // 必填，生成签名的随机串
      signature: wechat.signature,// 必填，签名
      jsApiList: [
        'updateAppMessageShareData',
        'updateTimelineShareData',
      ] // 必填，需要使用的JS接口列表
    });

    wx.ready(function () {   //需在用户可能点击分享按钮前就先调用
      wx.updateAppMessageShareData({
        title, // 分享标题
        desc, // 分享描述
        link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl, // 分享图标
        success: function () {
          // 设置成功
        }
      });
      wx.updateTimelineShareData({
        title, // 分享标题
        link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl, // 分享图标
        success: function () {
          // 设置成功
        }
      });
    });
  }, e => console.log(e));
};

Vue.config.productionTip = false

Vue.use(VueAxios, axios)
Vue.use(VueFullPage)
Vue.component('video-bg', VideoBg)

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
