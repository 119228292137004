import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/m',
    component: () => import('../views/mobile/Index.vue'),
  },
  {
    path: '/m/about',
    component: () => import('../views/mobile/About.vue'),
  },
  {
    path: '/m/agenda',
    component: () => import('../views/mobile/Agenda.vue'),
  },
  {
    path: '/m/guide',
    component: () => import('../views/mobile/Guide.vue'),
  },
//   {
//     path: '/m/guest',
//     component: () => import('../views/mobile/Guest.vue'),
//   },
//   {
//     path: '/m/news',
//     component: () => import('../views/mobile/News.vue'),
//   },
//   {
//     path: '/m/partner',
//     component: () => import('../views/mobile/Partner.vue'),
//   },
  {
    path: '/',
    component: () => import('../views/pc/Index.vue'),
  },
  {
    path: '/review',
    component: () => import('../views/pc/Review.vue'),
  }
]

const router = new VueRouter({
  routes
})

export default router
